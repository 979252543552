.slider {
  width: 100%;
  height: var(--height);
  overflow: hidden;
  mask-image: linear-gradient(to right, transparent, #000 10% 90%, transparent);
}

.slider .list {
  display: flex;
  width: 100%;
  min-width: calc(var(--width) * var(--quantity));
  position: relative;
}

.slider .list .item {
  width: var(--width);
  height: var(--height);
  position: absolute;
  left: 100%;
  animation: autoRun 10s linear infinite;
  transition: filter 0.5s;
  animation-delay: calc(
    (10s / var(--quantity)) * (var(--position) - 1)
  ) !important;
  margin: 0 5px;
}

.slider .list .item img {
  width: 50px;
  height: 50px;
  object-fit: cover;
}

@keyframes autoRun {
  from {
    left: 100%;
  }
  to {
    left: calc(var(--width) * -1);
  }
}

.slider:hover .item {
  animation-play-state: paused !important;
  filter: grayscale(1);

  &:hover {
    filter: grayscale(0);
  }
}

.slider[reverse="true"] .item {
  animation: reversePlay 10s linear infinite;
}

@keyframes reversePlay {
  from {
    left: calc(var(--width) * -1);
  }
  to {
    left: 100%;
  }
}

@media only screen and (max-width: 575px) {
  .slider:hover .item {
    animation-play-state: running !important;
    filter: none;
  }

  .slider .item:hover {
    filter: none;
  }
}

.portfolio-holder__right .about-img {
  text-align: left;
  margin-bottom: 32px;
}

.portfolio-holder__right .about-img img {
  width: 150px;
  height: 150px;
  object-fit: cover;
  object-position: top;
  border-radius: 50%;
}

.portfolio .portfolio-holder .portfolio-holder__right #about h2 {
  flex: 0 0 100%;
  margin-bottom: 42px;
}

@media only screen and (max-width: 991px) {
  .portfolio .portfolio-holder .portfolio-holder__right #about .about-img {
    flex: 0 0 30%;
    margin-bottom: 0;
  }

  .portfolio .portfolio-holder .portfolio-holder__right #about p {
    font-size: 14px;
  }

  .portfolio .portfolio-holder .portfolio-holder__right #about p:first-of-type {
    flex: 0 0 70%;
  }
}

@media only screen and (max-width: 575px) {
  .portfolio .portfolio-holder .portfolio-holder__right #about .about-img {
    margin-bottom: 18px;
  }

  .portfolio .portfolio-holder .portfolio-holder__right #about p:first-of-type {
    flex: 0 0 100%;
  }
}

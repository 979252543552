.portfolio-holder__left-heading {
    position: relative;
    font-weight: 700;
    font-size: 64px;
    line-height: 1;
    margin: 0;
    width: fit-content;
}

.portfolio-holder__left-heading .text-wrapper {
    position: relative;
    display: inline-block;
    overflow: hidden;
}

.portfolio-holder__left-heading .letter {
    transform-origin: 50% 100%;
    display: inline-block;
    line-height: 1em;
}

.portfolio .portfolio-holder .portfolio-holder__left {
    flex: 0 0 auto;
    width: 45%;
    height: 100%;
}

.portfolio-holder__left-holder {
    position: fixed;
    max-width: 45%;
}

.portfolio-holder__left-content {
    animation: describe 1.5s;
    animation-delay: 1500ms;
    animation-fill-mode: forwards;
    opacity: 0;
    padding-left: 3px;
    text-align: left;
    width: 80%;
}

.portfolio-holder__left-content h2 {
    font-size: 24px;
    margin: 0 0 32px 0;
}

.portfolio-holder__left-content p {
    font-size: 16px;
    margin-bottom: 80px;
}

.portfolio-navigation {
    margin-bottom: 80px;
}

.portfolio-holder__left-content .portfolio-navigation ul {
    padding-left: 0;
    width: fit-content;
}

.portfolio-holder__left-content .portfolio-navigation ul li {
    list-style: none;
    margin-bottom: 12px;
}

.portfolio-holder__left-content .portfolio-navigation ul li a {
    color: #b5b5b5;
    text-decoration: none;
    font-size: 18px;
    display: flex;
    flex-flow: row wrap;
    justify-content: left;
    align-items: center;
    text-transform: uppercase;
    transition: 300ms;
}

.portfolio-holder__left-content .portfolio-navigation ul li a .line {
    border: 1px solid #b5b5b5;
    width: 30px;
    margin-right: 18px;
    transition: 300ms;
}

.active {
    color: #fff !important;
}

.active-line {
    border-color: #fff !important;
    width: 60px !important;
}

.portfolio-holder__left-content .portfolio-navigation ul li a:hover {
    color: #fff;
}

.portfolio-holder__left-content .portfolio-navigation ul li a:hover .line {
    border-color: #fff;
    width: 60px;
}

.portfolio-contact {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
}

.portfolio-contact a {
    display: flex;
    margin-right: 25px;
}

.portfolio-contact a:last-child {
    margin-right: 0;
}

.portfolio-contact a svg {
    width: 25px;
    height: 25px;
    filter: brightness(0.5);
    transition: 300ms;
}

.portfolio-contact a:hover svg {
    filter: brightness(1);
}

.portfolio-contact a:nth-child(1) svg {
    margin-bottom: 1px;
}

.portfolio-contact a:nth-child(1):hover svg {
    fill: #F1502F;
}

.portfolio-contact a:nth-child(2) svg {
    width: 27px;
    height: 27px;
}

.portfolio-contact a:nth-child(2):hover svg {
    fill: #0077B5;
}

.portfolio-contact a:nth-child(3) svg {
    width: 23px;
    height: 23px;
}

.portfolio-contact a:nth-child(3):hover svg path {
    fill: #000;
}

.portfolio-contact a:nth-child(3) svg path{
    transition: 200ms;
}

.portfolio-contact a:nth-child(4) svg {
    width: 32px;
    height: 32px;
}


.portfolio-contact a:nth-child(4):hover svg {
    fill: #C71610;
}

@keyframes describe {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@media only screen and (max-width: 991px) {

    .portfolio-contact p {
        flex: 0 0 100%;
        text-align: left;
    }

}

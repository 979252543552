.header {
  height: 70px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9;
}

.header .header-holder svg {
  display: none;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.hamburger {
  display: none;
}

.hamburger-icon {
  display: none;
}

@media only screen and (max-width: 991px) {
  .header {
    background: #112026ff;
    height: 70px;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 9;
  }

  .header .header-holder svg {
    display: block;
    width: 30px;
    height: 30px;
    /* position: fixed; */
    left: 13px;
    top: 20px;
    z-index: 999;
  }

  .header .header-holder img {
    display: block;
    width: 30px;
    height: 30px;
    position: fixed;
    left: 13px;
    top: 20px;
    z-index: 999;
  }

  .hamburger {
    width: 100%;
    height: 100vh;
    background: #112026ff;
    position: fixed;
    top: 0;
    z-index: 99;
    display: none;
    transition: all 0.3s ease-in-out;
  }

  .hamburger.show {
    display: block;
  }

  /* Hamburger ikonica stilovi */
  .hamburger-icon {
    display: block;
    position: fixed;
    top: 23px;
    right: 23px;
    width: 30px;
    height: 30px;
    z-index: 999;
    cursor: pointer;
  }

  .hamburger-icon .line {
    width: 30px;
    height: 2px;
    background-color: #ecf0f1;
    display: block;
    margin: 6px auto;
    transition: all 0.3s ease-in-out;
  }

  .hamburger-icon.is-active .line:nth-child(1) {
    transform: translateY(8px) rotate(45deg);
  }

  .hamburger-icon.is-active .line:nth-child(2) {
    opacity: 0;
  }

  .hamburger-icon.is-active .line:nth-child(3) {
    transform: translateY(-8px) rotate(-45deg);
  }

  .hamburger .hamburger-menu {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    height: 100%;
  }

  .hamburger .hamburger-menu .portfolio-navigation {
    list-style: none;
    text-align: left;
    margin-bottom: 48px;
    padding-left: 13px;
  }

  .hamburger .hamburger-menu .portfolio-navigation li {
    margin-bottom: 12px;
  }

  .hamburger .hamburger-menu .portfolio-navigation li:last-of-type {
    margin-bottom: 0;
  }

  .hamburger .hamburger-menu .portfolio-navigation li a {
    text-decoration: none;
    font-size: 22px;
    color: #fff;
  }

  .portfolio-contact {
    padding-left: 13px;
  }

  .portfolio-contact a:nth-child(2):hover svg{
    fill: #F1502F !important;
  }

  .portfolio-contact a:nth-child(3):hover svg path{
    fill: #0077B5 !important;
  }

  .portfolio-contact a:nth-child(4):hover svg path{
    fill: #000 !important;
  }

  .portfolio-contact a:nth-child(5):hover svg{
    fill: #C71610 !important;
  }


}

/* html {
  background: linear-gradient(
    180deg,
    rgba(17, 32, 38, 1) 0%,
    rgba(23, 43, 51, 1) 70%,
    rgba(29, 49, 58, 1) 100%
  );
  overflow-x: clip;
  height: 100%;
} */
*,
body {
  padding: 0;
  margin: 0;
  color: #f3f3f3;
  font-family: "Noto Serif", serif;
}

body {
  background: linear-gradient(
    180deg,
    rgba(17, 32, 38, 1) 0%,
    rgba(23, 43, 51, 1) 70%,
    rgba(29, 49, 58, 1) 100%
  );
  overflow-x: clip;
  height: 100%;
}

.portfolio {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  text-align: center;
  height: 100%;
  position: relative;
  padding-top: 120px;
}

.portfolio .portfolio-holder {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  align-content: center;
  justify-content: space-between;
  z-index: 2;
}

.portfolio #dotsCanvas {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
}

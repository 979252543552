*,
body {
  padding: 0;
  margin: 0;
  color: #f3f3f3;
  font-family: "Noto Serif", serif;
}

body {
  /*height: 100vh;*/
  background: linear-gradient(
    180deg,
    rgba(17, 32, 38, 1) 0%,
    rgba(23, 43, 51, 1) 70%,
    rgba(29, 49, 58, 1) 100%
  );

  /* background-image: radial-gradient(at 50% 50%, #112026, #1d313a); */
  /*background-color: rgb(18, 36, 43) !important;*/
  overflow-x: clip;
}

/*.cursor {*/
/*    position: absolute;*/
/*    width: 500px;*/
/*    height: 500px;*/
/*    border-radius: 50%;*/
/*    background-color: rgba(25, 51, 61, 0.3);*/
/*    pointer-events: none;*/
/*    transform: translate(-50%, -50%);*/
/*    transition: background-color 0.3s;*/
/*}*/

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #112026FF;
}

.circle {
  border: 8px solid #f3f3f3;
  border-top: 8px solid #1D313AFF;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.space{
  width: 20px;
}


.portfolio {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  text-align: center;
  height: 100%;
  position: relative;
  padding-top: 120px;
}

.portfolio .portfolio-holder {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  align-content: center;
  justify-content: space-between;
  z-index: 2;
}

.portfolio #dotsCanvas {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
}

.portfolio .portfolio-holder .portfolio-holder__right {
  animation: describe 1.5s;
  animation-delay: 1500ms;
  animation-fill-mode: forwards;
  opacity: 0;
  flex: 0 0 auto;
  width: 45%;
}

.portfolio .portfolio-holder .portfolio-holder__right .content-holder {
  margin-bottom: 120px;
  padding-top: 120px;
  margin-top: -120px;
}

.portfolio
  .portfolio-holder
  .portfolio-holder__right
  .content-holder:last-of-type {
  margin-bottom: 60px;
}

.portfolio .portfolio-holder .portfolio-holder__right .content-holder h2 {
  text-align: left;
  font-size: 56px;
  margin-bottom: 42px;
}

.portfolio .portfolio-holder .portfolio-holder__right .content-holder p {
  text-align: left;
  font-size: 16px;
  font-weight: 300;
}

.portfolio
  .portfolio-holder
  .portfolio-holder__right
  .content-holder
  .slider:first-of-type {
  margin-bottom: 48px;
}

.hamburger,
.hamburger-icon {
  display: none;
}

.no-scroll {
  overflow: hidden;
}

.header .header-holder img {
  display: none;
}


@media only screen and (max-width: 1290px) {
  .portfolio-holder__left-heading {
    font-size: 60px;
  }
}

@media only screen and (max-width: 1200px) {
  .portfolio-holder__left-heading {
    font-size: 50px;
  }

  .portfolio-holder__left-content h2 {
    font-size: 22px;
  }

  .portfolio-holder__left-content p {
    font-size: 14px;
    margin-bottom: 60px;
  }

  .portfolio .portfolio-holder .portfolio-holder__right .content-holder h2 {
    font-size: 40px;
  }
}

@media only screen and (max-width: 991px) {
  .header {
    background: #112026ff;
    height: 70px;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 9;
  }

  .header .header-holder img {
    display: block;
    width: 30px;
    height: 30px;
    position: fixed;
    left: 13px;
    top: 20px;
    z-index: 999;
  }

  .header {
    background: #112026ff;
    height: 70px;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 9;
  }

  /* Logo stilovi */
  .header .header-holder img {
    display: block;
    width: 30px;
    height: 30px;
    position: fixed;
    left: 13px;
    top: 20px;
    z-index: 999;
  }

  .hamburger-icon {
    display: block;
    position: fixed;
    top: 23px;
    right: 23px;
    width: 30px;
    height: 30px;
    z-index: 999;
    cursor: pointer;
    background: transparent;
    border: none;
  }

  .portfolio {
    padding-top: 100px;
  }

  .portfolio .portfolio-holder .portfolio-holder__left {
    width: 100%;
    height: auto;
  }

  .portfolio-holder__left-holder {
    position: relative;
    max-width: 100%;
  }

  .portfolio .portfolio-navigation,
  .portfolio .portfolio-contact {
    display: none;
  }

  .portfolio .portfolio-holder .portfolio-holder__right {
    width: 100%;
  }

  .portfolio .portfolio-holder .portfolio-holder__right .content-holder {
    margin-bottom: 80px;
  }

  .space{
    width: 15px;
  }


  .portfolio-contact a:nth-child(3) svg{
    width: 29px;
    height: 29px;
  }

  .portfolio-contact a:nth-child(4) svg{
    width: 26px;
    height: 25px;
  }

  .portfolio-contact a:nth-child(5) svg{
    width: 32px;
    height: 32px;
  }

  .portfolio-contact a:nth-child(6) svg{
    width: 28px;
    height: 28px;
  }
}

@media only screen and (max-width: 575px) {
  .portfolio-holder__left-heading {
    font-size: 37px;
  }

  .portfolio-holder__left-content h2 {
    font-size: 16px;
  }

  .portfolio-holder__left-content {
    width: 100%;
  }

  .portfolio-holder__left-content {
    flex: 0 0 100%;
    margin-bottom: 18px;
  }

  .portfolio .portfolio-holder .portfolio-holder__right .content-holder {
    margin-bottom: 60px;
  }

  .space{
    width: 10px;
  }
}
